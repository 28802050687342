<template>
  <v-card id="account-setting-card">
    <!-- <v-card-title class="justify-center">
      <span class="text-h5"><strong>TISSUE EVALUATION</strong></span>
    </v-card-title> -->
    <!-- tabs -->
    <v-tabs v-model="active_tab">
      <v-tab
        key="0"
        :disabled="readOnlyField === true"
      >
       <span>OS (oculus sinister)</span>
      </v-tab>
      <v-tab
        key="1"
        :disabled="readOnlyField1 === true"
      >
       <span>OD (oculus dextrus)</span>
        
      </v-tab>
      <!-- <v-tab
        v-for="tab of tabs"
        :key="tab.index"
      >
      {{tab.name}}
      </v-tab> -->
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="active_tab">
      <v-tab-item key="0">
        <SlitLampOS/>
      </v-tab-item>
      <v-tab-item key="1">
        <SlitLampOD />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
// import { ref } from '@vue/composition-api'
import SlitLampService from "@/service/SlitLamp.service";
import SlitLampOD from './SlitLampOD.vue' 
import SlitLampOS from './SlitLampOS.vue'

export default {
  components: {
    SlitLampOD,
    SlitLampOS,
  },

  setup() {
    // const tab = ref('')
    return {
      // tab: "tab2",
      readOnlyField: false,
      readOnlyField1: false,
      active_tab: 0,
    }
  },

  watch: {
    options: {
      handler() {
        this.getSlitLamp()
      },
      deep: true,
    },
  },

  async mounted() {
    this.getSlitLamp();
  },

  methods:{
    async getSlitLamp() {
      const DonorId = this.$route.params.donor_id;
      const service = new SlitLampService();
      let response = await service.Slit_lampOD(DonorId);
      if(response.os_id == null){
        //console.log("shubham")
        this.readOnlyField = true
        this.active_tab = "1"
      }if(response.od_id == null){
        //console.log("bhati")
        this.readOnlyField1 = true
        this.active_tab = "0"
      }if(response.os_id == null && response.od_id == null){
        //console.log("bhati new")
        this.readOnlyField = true
        this.readOnlyField1 = true
        this.active_tab = "2"
      }
    },
  }
}

</script>

	

